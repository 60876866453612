import { getField, updateField } from 'vuex-map-fields'
import { prepareUrl, uri } from '~/api/uri'

export const initState = {
  twoDaysTotal: {},
  twoDaysChart: {},
  sevenDaysTotal: {},
  sevenDaysChart: {},
  thirtyDaysTotal: {},
  thirtyDaysChart: {},
  monthlyTotal: {},
  monthlyChart: {}
}

export const state = () => {
  return {
    ...initState
  }
}

export const actions = {
  async twoDaysTotal ({ commit }) {
    const res = await this.$axios(uri.dashboard.twoDaysTotal)
    if (res.data) {
      commit('setTwoDaysTotal', res.data)
    }
  },
  async twoDaysChart ({ commit }) {
    const res = await this.$axios(uri.dashboard.twoDaysChart)
    if (res.data) {
      commit('setTwoDaysChart', res.data)
    }
  },
  async sevenDaysTotal ({ commit }) {
    const res = await this.$axios(uri.dashboard.sevenDaysTotal)
    if (res.data) {
      commit('setSevenDaysTotal', res.data)
    }
  },
  async sevenDaysChart ({ commit }) {
    const res = await this.$axios(uri.dashboard.sevenDaysChart)
    if (res.data) {
      commit('setSevenDaysChart', res.data)
    }
  },
  async thirtyDaysTotal ({ commit }) {
    const res = await this.$axios(uri.dashboard.thirtyDaysTotal)
    if (res.data) {
      commit('setThirtyDaysTotal', res.data)
    }
  },
  async thirtyDaysChart ({ commit }) {
    const res = await this.$axios(uri.dashboard.thirtyDaysChart)
    if (res.data) {
      commit('setThirtyDaysChart', res.data)
    }
  },
  async monthlyTotal ({ commit }, { year, month }) {
    const res = await this.$axios(prepareUrl(uri.dashboard.monthlyTotal, { year, month }))
    if (res.data) {
      commit('setMonthlyTotal', { year, month, data: res.data })
    }
  },
  async monthlyChart ({ commit }, { year, month }) {
    const res = await this.$axios(prepareUrl(uri.dashboard.monthlyChart, { year, month }))
    if (res.data) {
      commit('setMonthlyChart', { year, month, data: res.data })
    }
  }
}

export const mutations = {
  updateField,
  setTwoDaysTotal (state, value) {
    state.twoDaysTotal = value
  },
  setTwoDaysChart (state, value) {
    state.twoDaysChart = value
  },
  setSevenDaysTotal (state, value) {
    state.sevenDaysTotal = value
  },
  setSevenDaysChart (state, value) {
    state.sevenDaysChart = value
  },
  setThirtyDaysTotal (state, value) {
    state.thirtyDaysTotal = value
  },
  setThirtyDaysChart (state, value) {
    state.thirtyDaysChart = value
  },
  setMonthlyTotal (state, { year, month, data }) {
    state.monthlyTotal[`${year}${month}`] = data
  },
  setMonthlyChart (state, { year, month, data }) {
    state.monthlyChart[`${year}${month}`] = data
  }
}

export const getters = {
  getField,
  twoDaysTotal (state) {
    return state.twoDaysTotal
  },
  twoDaysChart (state) {
    return state.twoDaysChart
  },
  sevenDaysTotal (state) {
    return state.sevenDaysTotal
  },
  sevenDaysChart (state) {
    return state.sevenDaysChart
  },
  thirtyDaysTotal (state) {
    return state.thirtyDaysTotal
  },
  thirtyDaysChart (state) {
    return state.thirtyDaysChart
  },
  monthlyTotal (state) {
    return state.monthlyTotal
  },
  monthlyChart (state) {
    return state.monthlyChart
  }
}
